import { UsersAnalysisMetricsEnum } from "@/includes/types/statistics/usersAnalysis";
import i18n from "@/i18n/i18n";
import { getPeriodStatMetricHelpMessage, getPeriodStatMetricTitle } from "@/includes/utils/statistics";

import { ListConfig } from "piramis-base-components/src/components/Pi/components/DefaultList/logic/types";

export const metricGroups: ListConfig['data'] = [
  {
    group: {
      guid: "1",
      titleKey: i18n.t('users_analysis_metric_group_messages').toString(),
      groupItems: [
        {
          key: UsersAnalysisMetricsEnum.MessageCount,
          title: getPeriodStatMetricTitle(UsersAnalysisMetricsEnum.MessageCount),
          helpMessage: getPeriodStatMetricHelpMessage(UsersAnalysisMetricsEnum.MessageCount),
        },
        {
          key: UsersAnalysisMetricsEnum.ReplyCount,
          title: getPeriodStatMetricTitle(UsersAnalysisMetricsEnum.ReplyCount),
          helpMessage: getPeriodStatMetricHelpMessage(UsersAnalysisMetricsEnum.ReplyCount),
        },
      ]
    }
  },
  {
    group: {
      guid: "2",
      titleKey: i18n.t('users_analysis_metric_group_points').toString(),
      groupItems: [
        {
          key: UsersAnalysisMetricsEnum.ReputationTake,
          title: getPeriodStatMetricTitle(UsersAnalysisMetricsEnum.ReputationTake),
          helpMessage: getPeriodStatMetricHelpMessage(UsersAnalysisMetricsEnum.ReputationTake),
        },
        {
          key: UsersAnalysisMetricsEnum.ReputationAdd,
          title: getPeriodStatMetricTitle(UsersAnalysisMetricsEnum.ReputationAdd),
          helpMessage: getPeriodStatMetricHelpMessage(UsersAnalysisMetricsEnum.ReputationAdd),
        },
        {
          key: UsersAnalysisMetricsEnum.ApTake,
          title: getPeriodStatMetricTitle(UsersAnalysisMetricsEnum.ApTake),
          helpMessage: getPeriodStatMetricHelpMessage(UsersAnalysisMetricsEnum.ApTake),
        },
        {
          key: UsersAnalysisMetricsEnum.ApAdd,
          title: getPeriodStatMetricTitle(UsersAnalysisMetricsEnum.ApAdd),
          helpMessage: getPeriodStatMetricHelpMessage(UsersAnalysisMetricsEnum.ApAdd),
        },
        {
          key: UsersAnalysisMetricsEnum.XpTake,
          title: getPeriodStatMetricTitle(UsersAnalysisMetricsEnum.XpTake),
          helpMessage: getPeriodStatMetricHelpMessage(UsersAnalysisMetricsEnum.XpTake),
        },
        {
          key: UsersAnalysisMetricsEnum.XpAdd,
          title: getPeriodStatMetricTitle(UsersAnalysisMetricsEnum.XpAdd),
          helpMessage: getPeriodStatMetricHelpMessage(UsersAnalysisMetricsEnum.XpAdd),
        },
      ]
    }
  },
  {
    group: {
      guid: "3",
      titleKey: i18n.t('users_analysis_metric_group_punish').toString(),
      groupItems: [
        {
          key: UsersAnalysisMetricsEnum.PunishTake,
          title: getPeriodStatMetricTitle(UsersAnalysisMetricsEnum.PunishTake),
          helpMessage: getPeriodStatMetricHelpMessage(UsersAnalysisMetricsEnum.PunishTake),
        },
        {
          key: UsersAnalysisMetricsEnum.PunishAdd,
          title: getPeriodStatMetricTitle(UsersAnalysisMetricsEnum.PunishAdd),
          helpMessage: getPeriodStatMetricHelpMessage(UsersAnalysisMetricsEnum.PunishAdd),
        },
        {
          key: UsersAnalysisMetricsEnum.WarnTake,
          title: getPeriodStatMetricTitle(UsersAnalysisMetricsEnum.WarnTake),
          helpMessage: getPeriodStatMetricHelpMessage(UsersAnalysisMetricsEnum.WarnTake),
        },
        {
          key: UsersAnalysisMetricsEnum.WarnAdd,
          title: getPeriodStatMetricTitle(UsersAnalysisMetricsEnum.WarnAdd),
          helpMessage: getPeriodStatMetricHelpMessage(UsersAnalysisMetricsEnum.WarnAdd),
        },
      ]
    }
  },
  {
    group: {
      guid: "4",
      titleKey: i18n.t('users_analysis_metric_group_reactions').toString(),
      groupItems: [
        {
          key: UsersAnalysisMetricsEnum.ReactionCount,
          title: getPeriodStatMetricTitle(UsersAnalysisMetricsEnum.ReactionCount),
          helpMessage: getPeriodStatMetricHelpMessage(UsersAnalysisMetricsEnum.ReactionCount),
        },
        {
          key: UsersAnalysisMetricsEnum.PositiveReactionCount,
          title: getPeriodStatMetricTitle(UsersAnalysisMetricsEnum.PositiveReactionCount),
          helpMessage: getPeriodStatMetricHelpMessage(UsersAnalysisMetricsEnum.PositiveReactionCount),
        },
        {
          key: UsersAnalysisMetricsEnum.NegativeReactionCount,
          title: getPeriodStatMetricTitle(UsersAnalysisMetricsEnum.NegativeReactionCount),
          helpMessage: getPeriodStatMetricHelpMessage(UsersAnalysisMetricsEnum.NegativeReactionCount),
        },
        {
          key: UsersAnalysisMetricsEnum.NoneReactionCount,
          title: getPeriodStatMetricTitle(UsersAnalysisMetricsEnum.NoneReactionCount),
          helpMessage: getPeriodStatMetricHelpMessage(UsersAnalysisMetricsEnum.NoneReactionCount),
        },
        {
          key: UsersAnalysisMetricsEnum.ReactionMessageCount,
          title: getPeriodStatMetricTitle(UsersAnalysisMetricsEnum.ReactionMessageCount),
          helpMessage: getPeriodStatMetricHelpMessage(UsersAnalysisMetricsEnum.ReactionMessageCount),
        },
        {
          key: UsersAnalysisMetricsEnum.ReactionMessageCountTake,
          title: getPeriodStatMetricTitle(UsersAnalysisMetricsEnum.ReactionMessageCountTake),
          helpMessage: getPeriodStatMetricHelpMessage(UsersAnalysisMetricsEnum.ReactionMessageCountTake),
        },
        {
          key: UsersAnalysisMetricsEnum.ReactionCountTake,
          title: getPeriodStatMetricTitle(UsersAnalysisMetricsEnum.ReactionCountTake),
          helpMessage: getPeriodStatMetricHelpMessage(UsersAnalysisMetricsEnum.ReactionCountTake),
        },
        {
          key: UsersAnalysisMetricsEnum.PositiveReactionCountTake,
          title: getPeriodStatMetricTitle(UsersAnalysisMetricsEnum.PositiveReactionCountTake),
          helpMessage: getPeriodStatMetricHelpMessage(UsersAnalysisMetricsEnum.PositiveReactionCountTake),
        },
        {
          key: UsersAnalysisMetricsEnum.NegativeReactionCountTake,
          title: getPeriodStatMetricTitle(UsersAnalysisMetricsEnum.NegativeReactionCountTake),
          helpMessage: getPeriodStatMetricHelpMessage(UsersAnalysisMetricsEnum.NegativeReactionCountTake),
        },
        {
          key: UsersAnalysisMetricsEnum.NoneReactionCountTake,
          title: getPeriodStatMetricTitle(UsersAnalysisMetricsEnum.NoneReactionCountTake),
          helpMessage: getPeriodStatMetricHelpMessage(UsersAnalysisMetricsEnum.NoneReactionCountTake),
        },
      ]
    }
  },
  {
    group: {
      guid: "5",
      titleKey: i18n.t('users_analysis_metric_group_toxicity').toString(),
      groupItems: [
        {
          key: UsersAnalysisMetricsEnum.Toxicity,
          title: getPeriodStatMetricTitle(UsersAnalysisMetricsEnum.Toxicity),
          helpMessage: getPeriodStatMetricHelpMessage(UsersAnalysisMetricsEnum.Toxicity),
        },
        {
          key: UsersAnalysisMetricsEnum.SevereToxicity,
          title: getPeriodStatMetricTitle(UsersAnalysisMetricsEnum.SevereToxicity),
          helpMessage: getPeriodStatMetricHelpMessage(UsersAnalysisMetricsEnum.SevereToxicity),
        },
        {
          key: UsersAnalysisMetricsEnum.Profanity,
          title: getPeriodStatMetricTitle(UsersAnalysisMetricsEnum.Profanity),
          helpMessage: getPeriodStatMetricHelpMessage(UsersAnalysisMetricsEnum.Profanity),
        },
      ]
    }
  },
  {
    group: {
      guid: "6",
      titleKey: i18n.t('users_analysis_metric_group_other').toString(),
      groupItems: [
        {
          key: UsersAnalysisMetricsEnum.TriggerCall,
          title: getPeriodStatMetricTitle(UsersAnalysisMetricsEnum.TriggerCall),
          helpMessage: getPeriodStatMetricHelpMessage(UsersAnalysisMetricsEnum.TriggerCall),
        },
      ]
    }
  }
]
