import { GetUserStatisticsByPeriodReq } from "./GetUserStatisticsByPeriodReq";
import { TOXICITY_METRICS } from "./constants";
import {
  MetricChartData,
  UsersAnalysisApiRes,
  UsersAnalysisCategory,
  UsersAnalysisMetricsEnum, UsersAnalysisMode,
  UsersAnalysisPagination,
  UsersAnalysisPeriodEnum, UsersAnalysisPeriodLocalEnum, UsersAnalysisUsersRawRecord, UserTableDataRecord
} from "@/includes/types/statistics/usersAnalysis";
import { StatisticPeriodUserFilter } from "@/includes/types/statistics/statistics.types";
import { ClassProperties, constArrayIncludes } from "@/includes/types/util.types";
import { convertLabels, getMaxValuesForEachMetric, getPeriodStatMetricTitle } from "@/includes/utils/statistics";
import { errorNotification } from "@/includes/NotificationService";
import i18n from "@/i18n/i18n";
import { getMetricColorNew } from "@/includes/logic/statistics/users-analysis/utils";

import { FromToTicksReturnType } from "piramis-base-components/src/shared/utils/fromToTicks";

import { Column } from "ant-design-vue/types/table/column";
import { ApexOptions } from "apexcharts";

export class UsersModeAnalysis extends GetUserStatisticsByPeriodReq {
  constructor(
      chat_id: number,
      from: string,
      to: string,
      metrics: Array<UsersAnalysisMetricsEnum>,
      public ticks: FromToTicksReturnType,
      public calculateMaxValues: boolean,
      period?: UsersAnalysisPeriodEnum | undefined,
      pagination?: UsersAnalysisPagination,
      category?: UsersAnalysisCategory,
      users?: StatisticPeriodUserFilter,
  ) {
    super(chat_id, from, to, metrics, UsersAnalysisMode.Users, period, pagination, category, users)
  }

  static statistics: Array<UserTableDataRecord> | null = null

  static tableColumns: Array<ClassProperties<Partial<Column>>> = []

  isLoading = false

  maxValues: Record<string, number> | null = null

  get metricColumns() {
    return UsersModeAnalysis.tableColumns.slice(1)
  }

  async setMaxMetricsValues(data: UsersAnalysisApiRes<UsersAnalysisMode.Users>['data']) {
    if (data.length && data.every(d => Object.values(d.data).every(v => typeof v === 'object'))) {
      this.maxValues = await getMaxValuesForEachMetric(data as Array<{ data: Record<string, Record<string, number>> }>)
    } else {
      this.maxValues = {}
    }
  }

  requestUsersStat(): void {
    this.isLoading = true

    this.validateRequest()
        .then(() => {
          this.getUserStatisticsByPeriod<UsersAnalysisMode.Users>()
              .then(async (res) => {
                if (res) {
                  try {
                    this.updateColumns(this.metrics)

                    if (this.calculateMaxValues) {
                      await this.setMaxMetricsValues(res.data)
                    }

                    await this.prepareData(res.data, this.ticks)
                  } finally {
                    this.isLoading = false
                  }
                } else {
                  this.isLoading = false
                }
              })
        })
        .catch(error => {
          errorNotification(error)

          this.isLoading = false
        })
  }

  async getMetricData(statistics: UsersAnalysisUsersRawRecord['data'], metric: UsersAnalysisMetricsEnum, ticks: FromToTicksReturnType): Promise<UserTableDataRecord['metrics']> {
    const { mapTimeData } = ticks;

    const metricData = statistics[metric];
    const metricOptions = this.generateColumnOptions(metric, this.maxValues?.[metric]);
    const title = getPeriodStatMetricTitle(metric)

    if (metricData) {
      const metricHasDetails = !!this.period && typeof metricData === "object";
      let total: number | string | null = metricHasDetails ? null : +metricData;

      if (total && constArrayIncludes(TOXICITY_METRICS, metric)) {
        total = `${ total.toFixed(2) }%`
      }

      return Promise.resolve({
        [metric]: {
          total,
          options: metricOptions,
          series: metricHasDetails
              ? [ { name: title, data: Object.values(Object.assign({ ...mapTimeData }, metricData)) } ]
              : null,
        },
      });
    }

    return Promise.resolve({
      [metric]: {
        total: "-",
        options: metricOptions,
        series: [
          {
            name: title,
            data: Object.values(mapTimeData),
          },
        ],
      },
    });
  }

  async getUserActivityChart(record: UsersAnalysisUsersRawRecord): Promise<UserTableDataRecord['userActivityChart']> {
    if (this.category && record.events) {
      const isEnterCategory = this.category === UsersAnalysisCategory.Enter

      const fillColor = isEnterCategory
          ? 'rgba(var(--a-success), 1)'
          : 'rgba(var(--a-danger), 1)'

      const xLabel = isEnterCategory
          ? i18n.t('period_category_enter_title').toString()
          : i18n.t('period_category_leave_title').toString()

      return {
        series: [
          {
            data: record.events.map(e => {
              return {
                x: xLabel,
                y: [
                  new Date(e.begin).getTime(),
                  new Date(e.end).getTime(),
                ],
                fillColor,
              }
            })
          }
        ],
      }
    } else {
      return null
    }
  }

  async prepareUser(record: UsersAnalysisUsersRawRecord, ticks: FromToTicksReturnType): Promise<UserTableDataRecord> {
    const { data, events, ...userInfo } = record;

    const metrics: UserTableDataRecord['metrics'] = {};

    for (const metric of this.metrics) {
      const result = await this.getMetricData(data, metric, ticks);

      Object.assign(metrics, result);
    }

    return {
      user: userInfo,
      userActivityChart: await this.getUserActivityChart(record),
      metrics,
    };
  }

  async prepareAllUsers(data: Array<UsersAnalysisUsersRawRecord>, ticks: FromToTicksReturnType) {
    return await Promise.all(
        data.map(async (record) => await this.prepareUser(record, ticks))
    );
  }

  async prepareData(data: Array<UsersAnalysisUsersRawRecord>, ticks: FromToTicksReturnType) {
    try {
      UsersModeAnalysis.statistics = await this.prepareAllUsers(data, ticks);
    } catch (error) {
      errorNotification(error)
      UsersModeAnalysis.statistics = []
    }
  }

  generateColumnOptions(metric: UsersAnalysisMetricsEnum, maxValue: number | undefined): ApexOptions {
    return {
      chart: {
        type: 'bar',
        animations: {
          enabled: false
        },
        sparkline: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
        redrawOnParentResize: false
      },
      colors: [ getMetricColorNew(metric) ],
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      grid: {
        show: false,
        padding: {
          right: 0,
          left: 0,
          bottom: 0,
          top: 0
        }
      },
      xaxis: {
        labels: {
          show: false,
        },
        categories: convertLabels(this.ticks.timeTicks),
      },
      yaxis: {
        min: 0,
        max: maxValue,
        show: false
      },
      tooltip: {
        marker: {
          show: false
        },
        y: {
          formatter: (val: number, opts?: any): string => {
            if (constArrayIncludes(TOXICITY_METRICS, metric)) {
              return val.toFixed(2) + '%'
            }

            return val.toFixed(0)
          },
          title: {
            formatter: (seriesName: string): string => ''
          }
        }
      }
    }
  }

  updateColumns(metrics: Array<UsersAnalysisMetricsEnum>) {
    const baseColumns: Array<ClassProperties<Partial<Column>>> = [ {
      title: i18n.t('stat_users_data_table_col_user').toString(),
      key: 'user-info',
      width: 270,
      scopedSlots: { customRender: 'user-info' },
    } ]

    UsersModeAnalysis.tableColumns = [
      ...baseColumns,
      ...metrics.map<ClassProperties<Partial<Column>>>(metric => ({
        title: getPeriodStatMetricTitle(metric),
        key: metric,
        width: 250,
        scopedSlots: { customRender: metric },
        defaultSortOrder: 'descend',
        sorter: (a: MetricChartData, b: MetricChartData) => 0,
        sortDirections: [ 'ascend', 'descend' ]
      }))
    ]
  }

}
