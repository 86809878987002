



















































































import TelegramProfileButton from "@/components/TelegramProfileButton.vue";
import { StatisticPeriodUserFilter } from "@/includes/types/statistics/statistics.types";
import { UserAnalysisStatistics, UsersModeAnalysis } from "@/includes/logic/statistics/users-analysis";

import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo.vue'
import TableExportButtons from 'piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue'
import { ApexChartLocales } from "piramis-base-components/src/shared/types/ApexChartSeries.types";

import { Component, Emit, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
import { ApexOptions } from "apexcharts";

@Component({
  data() {
    return {
      UsersModeAnalysis
    }
  },
  components: {
    TelegramProfileButton,
    UserMainInfo,
    VueApexCharts,
    TableExportButtons
  },
})
export default class UserStatUsersDataNew extends Vue {

  @Prop() usersAnalysis!: UserAnalysisStatistics

  rangeBarOptions: ApexOptions = Object.freeze({
    chart: {
      defaultLocale: this.$i18n.locale,
      locales: ApexChartLocales,
      zoom: {
        enabled: false
      },
      type: 'rangeBar',
      animations: {
        enabled: false
      },
      toolbar: {
        show: false,
      },
      offsetY: -5
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
        top: -10,
        bottom: 0
      }
    },
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    tooltip: {
      fixed: {
        enabled: true,
      },
    },
    xaxis: {
      type: 'datetime'
    },
    yaxis: {
      axisTicks: {
        show: false,
      },
      show: false
    }
  })

  @Emit()
  updateUsers(payload: StatisticPeriodUserFilter | null) {
    return payload
  }

  //https://1x.antdv.com/components/table/#API api here
  handleChange(pagination: any, _: any, sorter: any) {
    const tableOrder = !sorter?.order
      ? undefined
      : sorter?.order === 'ascend' ? 'Asc' : 'Desc'

    const { page, limit, direction } = this.usersAnalysis

    if (pagination.current !== page) {
      this.usersAnalysis.updatePagination(pagination.current)
      this.usersAnalysis.getUsersStatistics(false)

      return
    }

    if (tableOrder && tableOrder !== direction) {
      this.usersAnalysis.updateSorter(sorter.columnKey, tableOrder)
      this.usersAnalysis.getUsersStatistics(false)

      return
    }

    if (limit !== pagination.pageSize) {
      this.usersAnalysis.updateLimit(pagination.pageSize)

      return
    }
  }

  get chartWidth() {
    if (this.$screen.xl) {
      return '300px'
    }

    return '250px'
  }

  gotoUserProfile(userId:number) {
    this.$router.push({ name: 'user_info', params: { userId: userId.toString() } })
  }
}
