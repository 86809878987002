





















import { UsersAnalysisMetricsEnum } from "@/includes/types/statistics/usersAnalysis";
import SelectedMetricCard from "@/components/statistic/components/SelectedMetricCard.vue";
import { TotalModeAnalysis } from "@/includes/logic/statistics/users-analysis";

import { Component, Emit, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'

@Component({
  data() {
    return {
      TotalModeAnalysis
    }
  },
  components: {
    SelectedMetricCard,
    VueApexCharts
  }
})
export default class UserStatGlobalCardNew extends Vue {
  @Prop() totalUsersAnalysis!: TotalModeAnalysis

  @Emit()
  removeMetric(metric: UsersAnalysisMetricsEnum) {
    return metric
  }
}
