import {
  UsersAnalysisCategory,
  UsersAnalysisMetricsEnum,
  UsersAnalysisMode, UsersAnalysisPagination,
  UsersAnalysisPeriodEnum
} from "@/includes/types/statistics/usersAnalysis";
import { StatisticPeriodUserFilter } from "@/includes/types/statistics/statistics.types";
import StatisticsApi from "@/includes/Api/Statistics.api";
import { errorNotification } from "@/includes/NotificationService";
import i18n from "@/i18n/i18n";

import moment from "moment/moment";

export class GetUserStatisticsByPeriodReq {
  constructor(
      public chat_id: number,
      public from: string,
      public to: string,
      public metrics: Array<UsersAnalysisMetricsEnum>,
      public mode: UsersAnalysisMode,
      public period?: UsersAnalysisPeriodEnum | undefined,
      public pagination?: UsersAnalysisPagination,
      public category?: UsersAnalysisCategory,
      public users?: StatisticPeriodUserFilter,
  ) {
  }

  validateRequest(): Promise<boolean | string> {
    return new Promise<boolean>((resolve, reject) => {
      if (moment(this.from).diff(moment(this.to), 'd') < -370) {
        reject(i18n.t('period_limit'))
      } else if (this.metrics.length <= 0) {
        reject(i18n.t('empty_metrics_notification'))
      } else {
        resolve(true)
      }
    })
  }

  getUserStatisticsByPeriod<T>() {
    return StatisticsApi.getUserStatisticsByPeriod<T>({
      chat_id: this.chat_id,
      from: this.from,
      to: this.to,
      metrics: this.metrics,
      mode: this.mode,
      period: this.period,
      pagination: this.pagination,
      category: this.category,
      users: this.users,
    })
        .then(({ data }) => data)
        .catch(errorNotification)
  }
}
