













































import { ApexChartSeries } from "piramis-base-components/src/shared/types/ApexChartSeries.types";

import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import VueApexCharts from "vue-apexcharts";
import { ApexOptions } from "apexcharts";

@Component({
  components: { VueApexCharts }
})
export default class SelectedMetricCard extends Vue {
  @Prop() title!: string

  @Prop() metric!: string

  @Prop() series!: ApexChartSeries | null | undefined

  @Prop() options!: ApexOptions | undefined

  @Prop() total!: number | string | null | undefined

  @Emit()
  removeMetric() {
    return this.metric
  }
}
