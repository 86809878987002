import { GetUserStatisticsByPeriodReq } from "./GetUserStatisticsByPeriodReq";
import { TOXICITY_METRICS } from "./constants";
import {
  MetricChartData,
  UsersAnalysisApiRes,
  UsersAnalysisCategory,
  UsersAnalysisMetricsEnum,
  UsersAnalysisMode,
  UsersAnalysisPeriodEnum,
  UsersAnalysisTotal
} from "@/includes/types/statistics/usersAnalysis";
import { errorNotification } from "@/includes/NotificationService";
import { getMetricColorNew } from "@/includes/logic/statistics/users-analysis/utils";
import { convertLabels, getPeriodStatMetricTitle } from "@/includes/utils/statistics";
import { constArrayIncludes } from "@/includes/types/util.types";
import { StatisticPeriodUserFilter } from "@/includes/types/statistics/statistics.types";

import { FromToTicksReturnType } from "piramis-base-components/src/shared/utils/fromToTicks";

import { ApexOptions } from "apexcharts";

export class TotalModeAnalysis extends GetUserStatisticsByPeriodReq {
  constructor(
      public chat_id: number,
      public from: string,
      public to: string,
      public metrics: Array<UsersAnalysisMetricsEnum>,
      public ticks: FromToTicksReturnType,
      public period?: UsersAnalysisPeriodEnum | undefined,
      public category?: UsersAnalysisCategory,
      public users?: StatisticPeriodUserFilter,
  ) {
    super(chat_id, from, to, metrics, UsersAnalysisMode.Total, period, undefined, category, users)
  }

  static statistics: Array<MetricChartData> | null = null

  isLoading = false

  count = 0

  requestTotalStat(): void {
    this.isLoading = true

    this.validateRequest()
        .then(() => {
          this.getUserStatisticsByPeriod<UsersAnalysisMode.Total>()
              .then(res => {
                if (res) {
                  this.generateCharts(res)
                      .then(stat => {
                        TotalModeAnalysis.statistics = stat
                      })
                      .catch(errorNotification)
                      .finally(() => {
                        this.isLoading = false
                      })

                  this.count = res.count
                } else {
                  this.isLoading = false
                }
              })
        })
        .catch(error => {
          errorNotification(error)

          this.isLoading = false
        })
  }

  columnOptions(metric: UsersAnalysisMetricsEnum): ApexOptions {
    return {
      chart: {
        type: 'bar',
        height: '100',
        animations: {
          enabled: false
        },
        sparkline: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
      },
      colors: [ getMetricColorNew(metric) ],
      plotOptions: {
        bar: {
          horizontal: false,
          barHeight: '100%'
        },
      },
      dataLabels: {
        enabled: false
      },
      grid: {
        show: false,
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }
      },
      xaxis: {
        labels: {
          show: false,
        },
        categories: convertLabels(this.ticks.timeTicks),
      },
      yaxis: {
        show: false,
        decimalsInFloat: 2
      },
      tooltip: {
        fixed: {
          enabled: true,
        },
        marker: {
          show: false
        },
        y: {
          formatter: (val: number, opts?: any): string => {
            if (constArrayIncludes(TOXICITY_METRICS, metric)) {
              return val.toFixed(2) + '%'
            }

            return val.toFixed(0)
          },
          title: {
            formatter: (seriesName: string): string => ''
          }
        }
      }
    }
  }

  getMetricData(statistics: UsersAnalysisTotal, metric: UsersAnalysisMetricsEnum, fromToTicks: FromToTicksReturnType) {
    const { mapTimeData } = fromToTicks

    const metricData = statistics[metric]
    const title = getPeriodStatMetricTitle(metric)

    return new Promise<MetricChartData>(resolve => {
      if (metricData) {
        const metricHasDetails = typeof metricData === 'object'
        let total: number | string = metricHasDetails ? +metricData.total : +metricData

        if (constArrayIncludes(TOXICITY_METRICS, metric)) {
          total = `${ total.toFixed(2) }%`
        }

        resolve({
          metric,
          total,
          title: getPeriodStatMetricTitle(metric),
          options: this.columnOptions(metric),
          series: metricHasDetails ? [ {
            name: title,
            data: Object.values(Object.assign({ ...mapTimeData }, metricData.details))
          } ] : null
        })
      }

      resolve({
        metric,
        title: title,
        total: '-',
        options: this.columnOptions(metric),
        series: [ {
          name: title,
          data: Object.values(mapTimeData)
        } ],
      })
    })

  }

  totalField(statistics: UsersAnalysisApiRes<UsersAnalysisMode.Total>, fromToTicks: FromToTicksReturnType) {
    return this.metrics.map((metric: UsersAnalysisMetricsEnum) => this.getMetricData(statistics, metric, fromToTicks))
  }

  generateCharts(rawStat: UsersAnalysisApiRes<UsersAnalysisMode.Total>) {
    return Promise.all(this.totalField(rawStat, this.ticks))
        .then(res => res)
  }
}
