















































































































import SetPeriod from '@/views/chat/statistics/components/mainStatistics/setPeriod.vue'
import UserStatGlobalCardNew from '@/components/statistic/users-analysis/UserStatGlobalCardNew.vue'
import UserStatUsersDataNew from '@/components/statistic/users-analysis/UserStatUsersDataNew.vue'
import TagsSelect from '@/components/statistic/users-analysis/TagsSelect.vue'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import {
  UsersAnalysisCategory,
  UsersAnalysisMetricsEnum,
  UsersAnalysisPeriodLocalEnum
} from "@/includes/types/statistics/usersAnalysis";
import TableSearch from "@/components/statistic/components/TableSearch.vue";
import { metricGroups } from "@/components/statistic/users-analysis/metricGroups";
import { warningNotification } from '@/includes/NotificationService'
import { isEnumValue } from "@/includes/types/util.types";
import { StatisticPeriodEnum, StatisticPeriodUserFilter, StatisticPeriodUserFilterMode } from "@/includes/types/statistics/statistics.types";
import { UserAnalysisStatistics } from "@/includes/logic/statistics/users-analysis";

import { SelectOption } from 'piramis-base-components/src/logic/types'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue'
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import DefaultList from 'piramis-base-components/src/components/Pi/components/DefaultList/DefaultList.vue'
import { Item } from "piramis-base-components/src/components/Pi/components/DefaultList/logic/types";
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'

import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  components: {
    TableSearch,
    TagsSelect,
    UserStatUsersDataNew,
    UserStatGlobalCardNew,
    SetPeriod,
    EmptyData,
    NotAvailableOptionsOverlay,
    SelectInput,
    DefaultList,
    ConfigField
  }
})
export default class UsersStatByPeriodNew extends Mixins(ModelSetter, TariffsTagsHelper) {
  @Prop({ type: Boolean, default: true, required: false }) showGlobal!:boolean

  usersAnalysis = new UserAnalysisStatistics()

  isMetricsModalOpen = false

  get metricsList() {
    return metricGroups
  }

  selectMetric(item: Item) {
    const { key } = item

    if(isEnumValue(UsersAnalysisMetricsEnum, key)) {
      if (this.usersAnalysis.selectedMetrics.includes(key)) {
        warningNotification(this.$t('statistic_add_metric_existing_warning_notification').toString())
        return
      }
      else if (this.usersAnalysis.selectedMetrics.length === 5) {
        warningNotification(this.$t('statistic_add_metric_limit_message').toString())
        return
      }

      this.usersAnalysis.addMetric(key)

      this.isMetricsModalOpen = false

      this.$nextTick(() => {
        this.updateMetrics()
      })
    }
  }

  removeMetric(metric: UsersAnalysisMetricsEnum) {
    this.usersAnalysis.removeMetric(metric)
    this.usersAnalysis.updatePagination(1)

    this.updateMetrics()
  }

  get categoryOptions(): Array<SelectOption> {
    return [
      {
        label: this.$t(`users_analysis_category_all`).toString(),
        value: null
      },
      {
        label: this.$t(`users_analysis_category_enter`).toString(),
        value: UsersAnalysisCategory.Enter
      },
      {
        label: this.$t(`users_analysis_category_leave`).toString(),
        value: UsersAnalysisCategory.Leave
      },
    ]
  }

  get periodGroupOptions():Array<SelectOption> {
    return [
      {
        label: this.$t(`statistics_group_day`).toString(),
        value: StatisticPeriodEnum.Day
      },
      {
        label: this.$t(`statistics_group_week`).toString(),
        value: StatisticPeriodEnum.Week
      },
      {
        label: this.$t(`statistics_group_month`).toString(),
        value: StatisticPeriodEnum.Month
      },
      {
        label: this.$t(`statistics_group_year`).toString(),
        value: StatisticPeriodEnum.Year
      },
      {
        label: this.$t(`users_analysis_group_none`).toString(),
        value: UsersAnalysisPeriodLocalEnum.None
      },
    ]
  }

  searchNewPeriod() {
    this.usersAnalysis.getStatistics()
  }

  updateUsers(payload: StatisticPeriodUserFilter | null) {
    this.usersAnalysis.setUsers(payload)

    this.usersAnalysis.getStatistics()
  }

  updateChart(_: SelectOption | null) {
    this.usersAnalysis.getStatistics()
  }

  updateMetrics() {
    this.usersAnalysis.getStatistics()
  }

  created() {
    const { dashboardExtendedUsers } = this.$store.state

    if (dashboardExtendedUsers.value.length) {
      this.usersAnalysis.setUsers({
        mode: StatisticPeriodUserFilterMode.Include,
        values: dashboardExtendedUsers.value
      })

      if (dashboardExtendedUsers.admin) {
        this.usersAnalysis.removeMetric(UsersAnalysisMetricsEnum.ReputationTake)
        this.usersAnalysis.addMetric(UsersAnalysisMetricsEnum.PunishAdd)
        this.usersAnalysis.addMetric(UsersAnalysisMetricsEnum.WarnAdd)
      }
    }

    this.usersAnalysis.getStatistics()
  }

  destroyed() {
    this.usersAnalysis.resetState()
  }
}
