import { UsersAnalysisMetricsEnum } from "@/includes/types/statistics/usersAnalysis";

export function getMetricColorNew<T extends keyof typeof UsersAnalysisMetricsEnum>(metric: T): string {
  switch (metric) {
    case UsersAnalysisMetricsEnum.ReplyCount:
      return 'rgba(var(--a-primary), 1)'
    case UsersAnalysisMetricsEnum.MessageCount:
      return 'rgba(var(--a-warning), 1)'
    case UsersAnalysisMetricsEnum.TriggerCall:
      return 'rgba(var(--a-dark), 1)'
    case UsersAnalysisMetricsEnum.ReputationTake:
      return '#546D64'
    case UsersAnalysisMetricsEnum.ReputationAdd:
      return '#4FB286'
    case UsersAnalysisMetricsEnum.ApTake:
      return '#5a189a'
    case UsersAnalysisMetricsEnum.ApAdd:
      return '#9d4edd'
    case UsersAnalysisMetricsEnum.XpTake:
      return '#804c36'
    case UsersAnalysisMetricsEnum.XpAdd:
      return '#e2b58b'
    case UsersAnalysisMetricsEnum.PunishAdd:
      return '#a4161a'
    case UsersAnalysisMetricsEnum.PunishTake:
      return '#e56b6f'
    case UsersAnalysisMetricsEnum.WarnTake:
      return '#fb6f92'
    case UsersAnalysisMetricsEnum.WarnAdd:
      return '#ffb3c6'
    case UsersAnalysisMetricsEnum.Toxicity:
      return '#9cafb7'
    case UsersAnalysisMetricsEnum.Profanity:
      return '#6b7c83'
    case UsersAnalysisMetricsEnum.SevereToxicity:
      return '#536267'
    case UsersAnalysisMetricsEnum.ReactionCount:
      return '#dda26b'
    case UsersAnalysisMetricsEnum.PositiveReactionCount:
      return '#88b04b'
    case UsersAnalysisMetricsEnum.NegativeReactionCount:
      return '#cd6133'
    case UsersAnalysisMetricsEnum.NoneReactionCount:
      return '#aec6cf'
    case UsersAnalysisMetricsEnum.ReactionMessageCount:
      return '#ea9999'
    case UsersAnalysisMetricsEnum.ReactionMessageCountTake:
      return '#7c594a'
    case UsersAnalysisMetricsEnum.ReactionCountTake:
      return '#cfc493'
    case UsersAnalysisMetricsEnum.PositiveReactionCountTake:
      return '#2a3747'
    case UsersAnalysisMetricsEnum.NegativeReactionCountTake:
      return '#8d98b3'
    case UsersAnalysisMetricsEnum.NoneReactionCountTake:
      return '#D0A69C'
    default:
      return '#004E89'
  }
}
