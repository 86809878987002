import {
  UsersAnalysisCategory,
  UsersAnalysisMetricsEnum,
  UsersAnalysisPeriodEnum,
  UsersAnalysisPeriodLocalEnum
} from "@/includes/types/statistics/usersAnalysis";
import { StatisticPeriodEnum, StatisticPeriodUserFilter } from "@/includes/types/statistics/statistics.types";
import { tickUnitByPeriod, updateDateToStart } from "@/includes/utils/statistics";
import { TotalModeAnalysis } from "./TotalModeAnalysis";
import { UsersModeAnalysis } from "./UsersModeAnalysis";
import store from "@/store/store";

import { fromToTicks, FromToTicksReturnType } from "piramis-base-components/src/shared/utils/fromToTicks";

import moment from "moment";
import GetUserInfoServiceImpl from "@/includes/logic/GetUserInfoServiceImpl";

const MOMENT_FORMAT = 'YYYY-MM-DD'

export class UserAnalysisStatistics {
  selectedMetrics: Array<UsersAnalysisMetricsEnum> = [ UsersAnalysisMetricsEnum.MessageCount, UsersAnalysisMetricsEnum.ReplyCount, UsersAnalysisMetricsEnum.ReputationTake ]

  totalStat: TotalModeAnalysis | null = null

  usersStat: UsersModeAnalysis | null = null

  removeMetric(metric: UsersAnalysisMetricsEnum) {
    this.selectedMetrics = this.selectedMetrics.filter(m => m !== metric)
    this.order = this.selectedMetrics[0]
  }

  addMetric(metric: UsersAnalysisMetricsEnum) {
    this.selectedMetrics.push(metric)
    this.order = this.selectedMetrics[0]
  }

  get hasMetrics() {
    return !!this.selectedMetrics.length
  }

  period: UsersAnalysisPeriodEnum = StatisticPeriodEnum.Week

  category: UsersAnalysisCategory | null = null

  users: StatisticPeriodUserFilter | null = null

  page = 1

  limit = 10

  count = 0

  direction: 'Asc' | 'Desc' = "Desc"

  order = this.selectedMetrics[0]

  updatePagination(page: number) {
    if (this.usersStat) {
      this.page = page
    }
  }

  updateSorter(column: UsersAnalysisMetricsEnum, direction: "Asc" | "Desc") {
    if (this.usersStat) {
      this.order = column
      this.direction = direction
    }
  }

  updateLimit(limit: number) {
    this.limit = limit

    this.updatePagination(1)
  }

  resetState() {
    this.selectedMetrics = [ UsersAnalysisMetricsEnum.MessageCount, UsersAnalysisMetricsEnum.ReplyCount, UsersAnalysisMetricsEnum.ReputationTake ]
    this.period = StatisticPeriodEnum.Week
    this.category = null
    this.users = null
  }

  setUsers(payload: StatisticPeriodUserFilter | null) {
    this.users = payload
  }

  dates: { from: string, to: string } = {
    from: moment().add(-3, 'month').format(MOMENT_FORMAT),
    to: moment().format(MOMENT_FORMAT),
  }

  ticks: FromToTicksReturnType = this.getFromToTicks()

  getFromToTicks() {
    const group = this.period === UsersAnalysisPeriodLocalEnum.None ? StatisticPeriodEnum.Week : this.period

    const { from, to } = updateDateToStart(this.dates, group)

    return fromToTicks(from, to, { unit: tickUnitByPeriod(group) })
  }

  loadUsersInfo(ids?: Array<string>) {
    return new Promise((resolve) => {
      const uIds = store.state.dashboardExtendedUsers.value.length ? store.state.dashboardExtendedUsers.value : (ids ?? [])

      if (uIds.length) {
        const getUserInfoService = new GetUserInfoServiceImpl(store.getters.chatId)

        getUserInfoService.getUsersInfos(uIds)
            .then(res => resolve(res ?? []))
            .then(() => {
              store.commit('clear_users')
            })
      } else {
        resolve([])
      }
    })
  }

  getStatistics() {
    this.ticks = this.getFromToTicks()

    this.getTotalStatistics()
    this.getUsersStatistics()
  }

  getTotalStatistics() {
    this.totalStat = new TotalModeAnalysis(
        store.getters.chatId,
        this.dates.from,
        this.dates.to,
        this.selectedMetrics,
        this.ticks,
        this.period === UsersAnalysisPeriodLocalEnum.None ? undefined : this.period,
        this.category ?? undefined,
        this.users ?? undefined,
    )

    this.totalStat.requestTotalStat()
  }

  getUsersStatistics(calculateMaxValues = true) {
    this.usersStat = new UsersModeAnalysis(
        store.getters.chatId,
        this.dates.from,
        this.dates.to,
        this.selectedMetrics,
        this.ticks,
        calculateMaxValues,
        this.period === UsersAnalysisPeriodLocalEnum.None ? undefined : this.period,
        {
          limit: this.limit,
          offset: (this.page - 1) * this.limit,
          direction: this.direction,
          order: this.order,
        },
        this.category ?? undefined,
        this.users ?? undefined,
    )

    this.usersStat.requestUsersStat()
  }
}
